:root {
  /* COLOR */
  color-scheme: light-dark;
  --dark: #171717;
  --gray: #6c757d;
  --light: #ffffff;

  --primary-color: #37FF8B;
  --secondary-color: #00ffff;
  --tertiary-color: #0000ff;

  --medium-dark: #343a40;
  --medium-dark2: #27292d;
  --medium-light: #e0e2e4;
  --medium-primary-color: #64ffda4d;
  --medium-tertiary-color: #1e1963;

  --background-color: light-dark(var(--light), var(--dark));
  /* --background-color2: light-dark(var(--light), var(--medium-tertiary-color)); */
  --background-color2: light-dark(var(--light), var(--medium-dark2));
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 5vh;
}


button,
p,
h3,
span,
::placeholder,
.animate-subtitle,
a {
  font-family: 'Kaush';
}

p {
  text-align: justify;
}

.main-title,
.title-page {
  color: white;
  font-family: 'Blacksword';
  text-align: center;
  text-wrap: pretty;
}

.background-app {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, var(--background-color) 10%, var(--background-color2) 50%, var(--background-color) 90%);
}